<template>
  <div class="table-wrap">
    <el-table
      v-bind="$attrs"
      ref="ktable"
      v-loading="loading"
      :class="['table', { 'no-data': data?.length === 0 }]"
      :data="data"
      v-on="$listeners"
    >
      <template>
        <el-table-column
          v-for="(item, index) in columns"
          v-bind="item"
          :key="item[columnKey] || index"
          :prop="item.prop"
          :label="item.label"
          :align="item.align || 'left'"
          :type="item.type"
          :index="item.index"
        >
          <!-- TODO: 嵌套表头(一层)，目前一层就可以了 -->
          <template v-if="item.nestedColumns && item.nestedColumns.length">
            <el-table-column
              v-for="(nestedItem, nestedIndex) in item.nestedColumns"
              :key="nestedItem[columnKey] || nestedIndex"
              :prop="nestedItem.prop"
              :label="nestedItem.label"
              :align="nestedItem.align || 'left'"
              :width="nestedItem.width || 'auto'"
              v-bind="nestedItem"
            >
              <!-- 表格头 -->
              <template v-if="nestedItem.headerSlotName" v-slot:header="scope">
                <slot :name="nestedItem.headerSlotName" v-bind="scope" />
              </template>

              <!-- 数据行 -->
              <template v-if="nestedItem.slotName" v-slot:default="scope">
                <slot :name="nestedItem.slotName" v-bind="scope" />
              </template>
            </el-table-column>
          </template>
          <!-- 表格头 -->
          <template v-if="item.headerSlotName" v-slot:header="scope">
            <slot :name="item.headerSlotName" v-bind="scope" />
          </template>

          <!-- 数据行 -->
          <template v-if="item.slotName" v-slot:default="scope">
            <slot :name="item.slotName" v-bind="scope" />
          </template>
          <!-- 通过配置 useHtmlRender，开启html渲染 -->
          <template v-else-if="item.useHtmlRender" v-slot="scope">
            <div class="cell-html" v-html="scope.row[item.prop]"></div>
          </template>
        </el-table-column>
      </template>
      <template #empty>
        <slot name="empty" />
      </template>
    </el-table>
    <KPagination
      v-if="needPagination"
      :background="paginationBackground"
      :hideOnSinglePage="hideOnSinglePage"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import KPagination from './../k-pagination/index.vue';
export default {
  name: 'KTable',
  components: {
    KPagination,
  },
  inheritAttrs: false,
  props: {
    columnKey: {
      type: String,
      default: 'id',
    },

    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    paginationBackground: {
      type: Boolean,
      default: true,
    },

    hideOnSinglePage: {
      type: Boolean,
      default: false,
    },

    needPagination: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    toggleRowSelection(row) {
      this.$refs['ktable'].toggleRowSelection(row);
    },
    clearSelection() {
      this.$refs['ktable'].clearSelection();
    },
  },
};
</script>

<style lang="less" scoped>
.table-wrap {
  padding: 20px 0;
  padding-bottom: 0;
  overflow: hidden;

  ::v-deep {
    .el-table {
      color: #111;

      .cell {
        padding: 0 10px;
      }

      &:not(.el-table--border) {
        .el-table__row {
          border-bottom: none;
        }

        .el-table__header {
          th.is-leaf,
          td {
            border-bottom: 0;
          }
        }
      }

      &.el-table--border {
        .el-table__empty-block {
          border-bottom: 1px solid #ebeef5;
        }
      }

      // table中checkbox的样式控制
      .el-checkbox__input:not(.is-checked, .is-indeterminate) {
        .el-checkbox__inner {
          border-color: #c2c2c6;
        }
      }

      &-column--selection .cell {
        padding-left: 10px;
      }

      thead {
        color: #666;

        th {
          font-weight: normal;
          background-color: #f6faff;
        }
      }
    }

    .el-table__fixed {
      &::before {
        display: none;
      }
    }
  }
}

.table {
  &.no-data::before {
    display: none;
  }
}
</style>
