import { component } from 'vuedraggable';

import dailyIcon from '@/assets/nav-icon/精选课程1@2x.png';
import dailyActiveIcon from '@/assets/nav-icon/精选课程2@2x.png';
import monthlyIcon from '@/assets/nav-icon/假期数据1@2x.png';
import monthlyActiveIcon from '@/assets/nav-icon/假期数据2@2x.png';
import semesterIcon from '@/assets/nav-icon/周培优数据报告1@2x.png';
import semesterActiveIcon from '@/assets/nav-icon/周培优数据报告2@2x.png';
import settingIcon from '@/assets/nav-icon/概况1@2x.png';
import settingActiveIcon from '@/assets/nav-icon/概况2@2x.png';

export default [
  {
    path: '/evaluation',
    name: 'evaluation',
    redirect: '/evaluation/settings',
    component: () => import('@/views/evaluation/entry.vue'),
    meta: {
      role: 'evl',
      roleId: '11317',
      menuName: '评价系统',
    },
    children: [
      {
        path: 'daily',
        name: 'daily',
        redirect: '/evaluation/daily/mark',
        component: () => import('@/views/evaluation/daily/entry.vue'),
        meta: {
          role: 'evl.daily',
          roleId: '11318',
          menuName: '日常表现',
          icon: dailyIcon,
          activeIcon: dailyActiveIcon,
        },
        children: [
          {
            path: '',
            name: 'dailyAssessment',
            redirect: '/evaluation/daily/mark',
            component: () => import('@/views/evaluation/daily/entry.vue'),
            meta: {
              role: 'evl.daily',
              roleId: '11318',
              menuName: '日常评价',
            },
            children: [
              {
                path: 'mark',
                name: 'dailyAssessmentIndex',
                component: () =>
                  import('@/views/evaluation/daily/mark/entry.vue'),
                meta: {
                  roleId: '11319',
                  role: 'evl.daily.page',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '',
            name: 'classReport',
            redirect: '/evaluation/daily/classReport',
            component: () => import('@/views/evaluation/daily/entry.vue'),
            meta: {
              role: 'evl.daily',
              roleId: '11318',
              menuName: '班级报表',
            },
            children: [
              {
                path: 'classReport',
                name: 'dailyAssessmentIndex',
                component: () =>
                  import('@/views/evaluation/daily/classReport/entry.vue'),
                meta: {
                  roleId: '11319',
                  role: 'evl.daily.page',
                  hideInMenu: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: 'monthly',
        name: 'monthlyEvaluation',
        redirect: '/evaluation/monthly/list',
        component: () => import('@/views/evaluation/monthly/entry.vue'),
        meta: {
          role: 'evl.month',
          roleId: '11320',
          menuName: '月度评价',
          icon: monthlyIcon,
          activeIcon: monthlyActiveIcon,
        },
        children: [
          {
            path: 'list',
            name: 'monthlyEvaluationList',
            component: () =>
              import('@/views/evaluation/monthly/list/entry.vue'),
            meta: {
              role: 'evl.month.page',
              roleId: '11321',
              hideInMenu: true,
            },
          },
          {
            path: 'mark',
            name: 'monthlyEvaluationMark',
            component: () =>
              import('@/views/evaluation/monthly/mark/entry.vue'),
            meta: {
              role: 'evl.month.page',
              roleId: '11321',
              hideInMenu: true,
            },
          },
          {
            path: 'report',
            name: 'monthlyEvaluationReport',
            component: () =>
              import('@/views/evaluation/monthly/report/entry.vue'),
            meta: {
              role: 'evl.month.page',
              roleId: '11321',
              hideInMenu: true,
            },
          },
        ],
      },
      {
        path: 'finalTerm',
        name: 'finalTermEvaluation',
        redirect: '/evaluation/finalTerm/mark',
        component: () => import('@/views/evaluation/finalTerm/entry.vue'),
        meta: {
          role: 'evl.period',
          roleId: '11322',
          menuName: '期末评价',
          icon: semesterIcon,
          activeIcon: semesterActiveIcon,
        },
        children: [
          {
            path: '',
            name: 'finalTermScores',
            component: () => import('@/views/evaluation/finalTerm/entry.vue'),
            redirect: '/evaluation/finalTerm/scores',
            meta: {
              role: 'evl.period',
              roleId: '11322',
              menuName: '期末成绩',
            },
            children: [
              {
                path: 'scores',
                name: 'finalTermScoresIndex',
                component: () =>
                  import('@/views/evaluation/finalTerm/scores/entry.vue'),
                meta: {
                  role: 'evl.period.page',
                  roleId: '11323',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '',
            name: 'finalTerm',
            component: () => import('@/views/evaluation/finalTerm/entry.vue'),
            redirect: '/evaluation/finalTerm/message',
            meta: {
              role: 'evl.period',
              roleId: '11322',
              menuName: '期末寄语',
            },
            children: [
              {
                path: 'message',
                name: 'finalTermMessageIndex',
                component: () =>
                  import('@/views/evaluation/finalTerm/message/entry.vue'),
                meta: {
                  role: 'evl.period.page',
                  roleId: '11323',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '',
            name: 'finalTermReport',
            component: () => import('@/views/evaluation/finalTerm/entry.vue'),
            redirect: '/evaluation/finalTerm/report',
            meta: {
              role: 'evl.period',
              roleId: '11322',
              menuName: '期末报告',
            },
            children: [
              {
                path: 'report',
                name: 'finalTermReportIndex',
                component: () =>
                  import('@/views/evaluation/finalTerm/report/entry.vue'),
                meta: {
                  role: 'evl.period.page',
                  roleId: '11323',
                  hideInMenu: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: '',
        name: 'evaluationStatistics',
        redirect: '/evaluation/statistics',
        component: () => import('@/views/evaluation/statistics/entry.vue'),
        meta: {
          role: 'evl.manage',
          roleId: '11344',
          menuName: '评价管理',
          icon: semesterIcon,
          activeIcon: semesterIcon,
        },
        children: [
          {
            path: 'statistics',
            name: 'evaluationStatisticsIndex',
            component: () => import('@/views/evaluation/statistics/entry.vue'),
            meta: {
              role: 'evl.manage.page',
              roleId: '11345',
              hideInMenu: true,
            },
          },
        ],
      },
      {
        path: '',
        name: 'evaluationSettings',
        redirect: '/evaluation/dimensionSettings',
        component: () => import('@/views/evaluation/settings/entry.vue'),
        meta: {
          role: 'evl.config',
          roleId: '11324',
          menuName: '管理配置',
          icon: settingIcon,
          activeIcon: settingActiveIcon,
        },
        children: [
          {
            path: 'dimensionSettings',
            name: 'evaluationDimensionSettings',
            redirect: '/evaluation/dimensionSettings/index',
            component: () =>
              import('@/views/evaluation/settings/dimension/entry.vue'),
            meta: {
              role: 'evl.config',
              roleId: '11324',
              menuName: '评价维度配置',
            },
            children: [
              {
                path: 'index',
                name: 'evaluationDimensionSettingsIndex',
                component: () =>
                  import('@/views/evaluation/settings/dimension/entry.vue'),
                meta: {
                  role: 'evl.config.page',
                  roleId: '11325',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: 'semester',
            name: 'semester',
            redirect: '/evaluation/semester/list',
            component: () =>
              import('@/views/evaluation/settings/semester/entry.vue'),
            meta: {
              role: 'evl.config',
              roleId: '11324',
              menuName: '评价周期管理',
            },
            children: [
              {
                path: 'list',
                name: 'semesterList',
                component: () =>
                  import('@/views/evaluation/settings/semester/list/entry.vue'),
                meta: {
                  role: 'evl.config.page',
                  roleId: '11325',
                  hideInMenu: true,
                },
              },
              {
                path: 'create',
                name: 'semesterCreate',
                component: () =>
                  import(
                    '@/views/evaluation/settings/semester/create/entry.vue'
                  ),
                meta: {
                  role: 'evl.config.page',
                  roleId: '11325',
                  hideInMenu: true,
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
